// import {Component, Prop, Provide, Vue} from 'vue-property-decorator';
// @Component({
//   components: {
//   },
//   computed: {
//   }
// })
// export default class WarmLifeDetail extends Vue {
//   @Provide() id:string = '';
//   created(){
//     const _this:any = this;
//     if(!_this.$base.isNull(_this.$route.query.i)){
//       _this.id = _this.$decryptBy(_this.$route.query.i)
//     }
//   }
// }
import {Component, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import CommentList from "@/views/Index/Components/CommentList.vue";
import ProductOptions from "@/views/Index/Components/ProductOptions.vue";
import {GetCommodityDetailsApi,GetExplain} from "@/network/modules";
import {AddCartReq, GetCommodityDetailsReq,GetExplanReq} from "@/interface/res";
import {Details, DetailsProperty} from "@/interface/pagas";
import {ClickCollectionReq} from "@/interface/res/common";
import {ClickCollectionApi} from "@/network/modules/common";

@Component({
  components: {
    CommentList,
    ProductOptions
  },
  computed: {
  }
})
export default class EmbroideryHomeDetail extends Vue {
  @Provide() menuIndex: number = 0;
  @Provide() commodityPhoneDetails: Array<string> = [];
  @Provide() id:number = 0;
  @Provide() loading:boolean = false;
  @Provide() totalPrice:number = 0;
  @Provide() advancedCustomShow:number = 0;
  @Provide() detail:Details = {
    commodityDetails:'',
    commodityId:-1,
    commoditySeries:-1,
    commodityType:-1,
    commodityName:"",
    commodityProperty:"",
    isCollection:false,
    commodityUnitCode:'',
    commodityUnitPrice:-1,
  };
  @Provide() detailImgs:Array<string> = [];
  @Provide() customImg:Array<string> = [];
  @Provide() tabIndex:number = 0;
  @Provide() commentIndex:number = 0;
  @Provide() patternMoerShow:boolean = false;
  @Provide() options:Array<DetailsProperty> = []
  @Provide() optionsNum:number = 0
  @Provide() detailOptions:any = {}
  @Provide() detailArr:Array<any> = []
  @Provide() iHeightMin:number = 0
  @Provide() iHeightMax:number = 0
  @Provide() iHeight:number = 0
  @Provide() iHeightMsg:string = ''
  @Provide() iHeightFalg:boolean = false
  @Provide() iHeightSeat:number = 0
  @Provide() iWidth:number = 0
  @Provide() iWidthMsg:string = ''
  @Provide() iWidthFalg:boolean = false
  @Provide() iWidthSeat:number = 0
  @Provide() scrollTime:any = null
  @Provide() scrollArray: Array<any> = []
  @Provide() newTotal: number = 0

  created(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query.i)){
      _this.id = _this.$decryptBy(_this.$route.query.i)
      _this.GetByDetailsFn()
    _this.GetExplan()
    }
  }

  mounted(){
    const _this:any = this;
    window.addEventListener('mousewheel',_this.mouseScroll , true);
    window.addEventListener("scroll", this.handleScroll, true); //监听滚动条变化
    let a = setTimeout(() => {
      for (let _index = 0; _index < _this.detailImgs.length; _index++) {
        let tabIndex = 'commodityTable' + _index;
        _this.scrollArray.push((document as any).getElementById(tabIndex).offsetTop)
      }
      console.log(_this.scrollArray);
      clearTimeout(a);
    }, 2500)
  }

  // 监听滚动条变化事件
  handleScroll() {
    let _this = this;
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    console.log(scrollTop,_this.scrollArray[0]);
    if(scrollTop < _this.scrollArray[0])return;
    for (let i = 0; i < _this.scrollArray.length; i++) {
      let height1 = _this.scrollArray[i] * (document.body.style as any).zoom;
      let height2 = _this.scrollArray[i + 1] * (document.body.style as any).zoom;
        // 如果不存在height2，意味着数据循环已经到了最后一个，设置左边菜单为最后一项即可
        if (!height2 || scrollTop + 200 >= height1 && scrollTop + 200 < height2) {
            //执行部分
            this.menuIndex = i;
          return;
        }
      }
  }

  /**
   * @Author HS
   * @Date 2021/7/16 10:30 上午
   * @Description: 计算价格
   * @Params: null
   * @Return: null
  */
  CalculateThePriceFn(){
    const _this:any = this;
    let totalPrice = 0
    let commodityPrice = _this.detail.commodityUnitPrice
    let imgPrice = _this.detailOptions.commodityUnitPrice
    let imgArr = _this.detailArr.filter((item:any) => item.propertyType == 2 && item.specsType == 1)
    imgArr.map((item:any) => {
      imgPrice = _this.$base.accMul(imgPrice,item.specsPriceCoefficient)
    })
    let commodityArr = _this.detailArr.filter((item:any) => item.propertyType == 2 && item.specsType == 2)
    commodityArr.map((item:any) => {
      commodityPrice = _this.$base.accMul(commodityPrice,item.specsPriceCoefficient)
    })

    totalPrice =  _this.$base.accAdd(imgPrice,commodityPrice)

    _this.totalPrice = Math.floor(_this.$base.accMul(totalPrice,100)) / 100
  }
  async GetExplan(){
    const _this: any = this;
    let params: GetExplanReq = {
      platformType: 2
    }
    const { data, code, msg } = await GetExplain(params);
    _this.customImg = JSON.parse(data)
  }
  /**
   * @Author HS
   * @Date 2021/7/15 10:01 上午
   * @Description: 产品详情
   * @Params: null
   * @Return: null
  */
  async GetByDetailsFn(){
    const _this:any = this;
    _this.loading = true
    let params:GetCommodityDetailsReq = {
      commodityId : _this.id
    }
    const res = await GetCommodityDetailsApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.advancedCustomShow = data.advancedCustomShow;
    _this.detail = data
    const { commodityProperty,commodityDetails,commodityPhoneDetails } = data
    _this.options = JSON.parse(commodityProperty)
    // _this.detailImgs = JSON.parse(commodityDetails);
    _this.commodityPhoneDetails = JSON.parse(commodityPhoneDetails);
    for (const item of JSON.parse(commodityDetails)) {
      const { value } = item;
      if (!_this.$base.isNull(value)) {
        _this.detailImgs.push(value);
      }
    };
    _this.options.map((item:any) => {
      // if (!_this.$base.isNull(item.modulePropertyValue) && typeof (item.modulePropertyValue) == "object"){
      if (item.propertyType == 1 || item.propertyType == 2){
        _this.optionsNum++
      }
    })
  }

  /**
   * @Author HS
   * @Date 2021/7/15 10:01 上午
   * @Description: 点击收藏
   * @Params: null
   * @Return: null
  */
  async ClickCollectionFn(){
    const _this:any = this;

    if(_this.$base.isLogin()){
      const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,'r=embroideryHomeDetail&i='+_this.id)
      _this.$base.goRouter(path)
      return
    }

    let params:ClickCollectionReq = {
      commodityId:_this.id
    }
    const res = await ClickCollectionApi(params)
    const { code, data, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.detail.isCollection = data

  }

  /**
   * @Author HS
   * @Date 2021/7/15 12:01 下午
   * @Description: 选项回调
   * @Params: { object ： data - true } [选中数据]
   * @Return: null
  */
  dataCallbackFn(data:any){
    const _this:any = this;
    if(data.propertyType == 1){
      if(typeof data.commodityUnitImg == 'string'){
        data.commodityUnitImg = JSON.parse(data.commodityUnitImg)
      }

      _this.$refs.carousel.setActiveItem(0)
      _this.detailOptions = {...data}
    }

    let newArr = [data];
    let res = _this.detailArr.filter((item:any) => item.propertyId == data.propertyId)[0]
    if(_this.$base.isNull(res)){
      _this.detailArr = [..._this.detailArr,...newArr]
    }
    else{
      let newIndex = _this.detailArr.findIndex((item:any) => item.propertyId == data.propertyId)
      _this.detailArr[newIndex] = data
    }
    if(_this.detailArr.length == _this.optionsNum){
      _this.CalculateThePriceFn()
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/15 6:10 下午
   * @Description: 产品宽度
   * @Params: { number ： iWidth - true } [宽度]
   * @Return: null
   */
  inputWidthCallbackFn(iWidth:number){
    const _this:any = this;
    _this.iWidth = iWidth
  }

  /**
   * @Author HS
   * @Date 2021/7/15 6:10 下午
   * @Description: 产品高度
   * @Params: { number ： iHeight - true } [高度]
   * @Return: null
  */
  inputHeightCallbackFn(iHeight:number){
    const _this:any = this;
    _this.iHeight = iHeight
  }

  /**
   * @Author HS
   * @Date 2021/7/16 9:27 上午
   * @Description: 产品宽度
   * @Params: { number ： iWidth - true } [宽度]
   * @Return: null
   */
  widthCallbackFn(iWidth:number){
    const _this:any = this;
    _this.iWidthFalg = true
    _this.iWidthSeat = iWidth
  }
  /**
   * @Author HS
   * @Date 2021/7/16 9:27 上午
   * @Description: 产品高度
   * @Params: { number ： iHeight - true } [高度]
   * @Params: { number ： iHeightMin - true } [最小高度]
   * @Params: { number ： iHeightMax - true } [最大高度]
   * @Return: null
  */
  heightCallbackFn(iHeight:number,iHeightMin:number,iHeightMax:number){
    const _this:any = this;
    _this.iHeightFalg = true
    _this.iHeightSeat = iHeight - 200
    _this.iHeightMin = iHeightMin
    _this.iHeightMax = iHeightMax
  }

  /**
   * @Author HS
   * @Date 2021/7/15 6:25 下午
   * @Description: 加入购物车
   * @Params: null
   * @Return: null
  */
  ShoppingCartFn(){
    const _this:any = this;
    if (_this.iHeightFalg && _this.$base.isNull(_this.iHeight)) {
      _this.iHeightMsg = '请输入高度'
      _this.jump(_this.iHeightSeat)
      return
    }
    if (
      (_this.iHeightFalg && parseFloat(_this.iHeight) < parseFloat(_this.iHeightMin)) ||
      (_this.iHeightFalg && parseFloat(_this.iHeight) >  parseFloat(_this.iHeightMax))
    ){
      _this.iHeightMsg = '您所输入的高度不在推荐高度内'
      _this.jump(_this.iHeightSeat)
      return
    }
    _this.iHeightMsg = ''

    if (_this.iWidthFalg && _this.$base.isNull(_this.iWidth)) {
      _this.iWidthMsg = '请输入宽度'
      _this.jump(_this.iWidthSeat)
      return
    }
    _this.iWidthMsg = ''

    let unitIds = '',specsIds:Array<number> = [];
    _this.detailArr.map((item:any)=>{
      if(item.propertyType == 1){
        unitIds = item.commodityUnitId
      }
      else if(item.propertyType == 2){
        specsIds.push(item.specsId)
      }
    })
    let params:AddCartReq = {
      commoditySeries: 1,
      commodityId: _this.id,
      cartPrice: _this.totalPrice,
      customParameter: '',
      specsIds: specsIds.join(','),
      unitIds: unitIds,
    }
    let size:{height?:string, width?:string} = {}
    if(_this.iHeightFalg) size.height = _this.iHeight
    if(_this.iWidthFalg) size.width = _this.iWidth
    if(!_this.$base.isNull(_this.iHeight)) size.height = _this.iHeight
    if(!_this.$base.isNull(_this.iWidth)) size.width = _this.iWidth
    if(!_this.$base.isNull(size)) params.customParameter = JSON.stringify(size)

    if(_this.$base.isLogin()){
      const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,'r=status&p='+JSON.stringify(params))
      _this.$base.goRouter(path)
      return
    }
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.status.path,'p='+JSON.stringify(params))
    _this.$base.goRouter(path)

  }

  /**
   * @Author HS
   * @Date 2021/8/26 11:15 上午
   * @Description: 选型卡切换
   * @Params: { number ：index - true } [下标]
   * @Return:
  */
  tabChangeFn(index:number){
    const _this:any = this;
    _this.tabIndex = index;
    _this.menuIndex = 0;
    const offsetTop = (document as any).getElementById('detailTabs').offsetTop
    _this.jump(offsetTop - 192)
  }

  /**
   * @Author HS
   * @Date 2021/7/16 9:19 上午
   * @Description: 滚动到产品高度
   * @Params: { number ： num - true } [目标位置]
   * @Return: null
  */
  jump(num: number) {
    const _this: any = this;
    let total = num;
    let distance = document.documentElement.scrollTop || document.body.scrollTop;
    // if(distance < total)return
    // 平滑滚动，时长500ms，每10ms一跳，共50跳
    let step = total / 100;
    let newTotal = 0;
    if (total > distance) {
      newTotal = total - distance;
      step = 30;
      smoothDown();
    } else {
       newTotal = distance - total;
      step = 30;
      smoothUp();
    }
    _this.newTotal = (newTotal / (step / 6)*1.5);
    function smoothDown() {
      if (distance < total) {
        distance += step;
        document.body.scrollTop = distance;
        document.documentElement.scrollTop = distance;
        _this.scrollTime = setTimeout(smoothDown, 6);
  		console.log(_this.scrollTime);
      } else {
        document.body.scrollTop = total;
        document.documentElement.scrollTop = total;
        clearTimeout(_this.scrollTime);
      }
    }
    function smoothUp() {
      if (distance > total) {
        distance -= step;
        document.body.scrollTop = distance;
        document.documentElement.scrollTop = distance;
        _this.scrollTime = setTimeout(smoothUp, 6);
      } else {
        document.body.scrollTop = total;
        document.documentElement.scrollTop = total;
        clearTimeout(_this.scrollTime);
      }
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/16 9:34 上午
   * @Description: 阻止回到顶部
   * @Params: null
   * @Return: null
   */
  mouseScroll(){
    const _this:any = this;
    clearTimeout(_this.scrollTime);
  }


  /**
   * @Author HS
   * @Date 2021/7/7 2:07 下午
   * @Description: 页面跳转
   * @Params: null
   * @Return: null
   */
  goRoute(str:string){
    const _this:any = this;
    if(_this.$base.isLogin()){
      const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,'r=diy&i='+_this.detail.galleryId)
      // _this.$base.goRouter(path)
      window.open(path, "_blank");
      return
    }
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.diy.path,'i='+_this.detail.galleryId)
    // _this.$base.goRouter(path)
    console.log(_this.detail.galleryId)
    window.open(path, "_blank");
  }
  
  menuChange(index: number) {
    const _this: any = this;
    _this.menuIndex = index;
    let tabIndex = 'commodityTable' + index;
    if(!_this.$base.isNull(_this.detailImgs)){
      if(!_this.$base.isNull((document as any).getElementById(tabIndex).offsetTop)){
        _this.jump(((document as any).getElementById(tabIndex).offsetTop-136) * (document.body.style as any).zoom);
      }
    }
  }
}
